<template>
    <Layout>
        <PageHeader :title="title" :items="items" />
        <div class="row">
            <div class="row">
                <div class="col-sm-12 col-md-6">
                    <div class="row">
                        <div class="col-1">
                            <button type="button" class="btn btn-outline-warning mb-3"
                                :disabled="statusRefresh == 0 ? true : false" @click="ListLp()">
                                <i class="fas fa-undo-alt"></i>
                            </button>
                        </div>
                        <div class="col-2">
                            <button type="button" v-b-modal.modal-center
                                class="btn btn-outline-warning waves-effect waves-light" data-toggle="modal"
                                data-target=".bs-example-modal-center">
                                Filter
                            </button>
                        </div>
                    </div>
                </div>
                <div class="col-sm-12 col-md-6">
                    <div id="tickets-table_filter" class="dataTables_filter text-md-end">
                        <label class="d-inline-flex align-items-center fw-normal">
                            Search:
                            <b-form-input v-model="filter" type="search" placeholder="Search..."
                                class="form-control form-control-sm ms-2"></b-form-input>
                        </label>
                        <br />
                        <b>Selected date range</b>: {{ selectedRangeLead }} Lead
                    </div>
                </div>
                <!-- End search -->
            </div>
            <b-modal id="modal-center" centered title="Filter" title-class="font-18" hide-footer>
                <div class="container">
                    <div class="row">
                        <div class="col-12">
                            <label>Date Select</label>
                            <flat-pickr class="form-control flatpickr-input" v-model="formData.date"
                                :config="multipleOption" placeholder="date"></flat-pickr>
                        </div>
                        <!-- selectbox pipeline -->
                        <div class="col-12">
                            <label>Pipeline</label>
                            <b-form-select v-model="formData.pipeline" :options="uniquePipeline" class="form-control">
                            </b-form-select>
                        </div>
                        <div class="col-12 mt-4">
                            <button class="btn btn-outline-info" @click="dateSend()">
                                Gönder
                            </button>
                        </div>
                    </div>
                </div>
            </b-modal>
            <div class="row">
                <div class="col-12 table-responsive">
                    <table class="table table-light  table-bordered table-fixed-header">
                        <thead>
                            <tr>
                                <th scope="col">Lead Source</th>
                                <th scope="col" v-for="(item, key) in statusPipe" :key="key">{{ item.name }}</th>
                                <th scope="col">Total</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr v-for="(data, key) in formattedOrderData" :key="key">
                                <th scope="row">{{ data.camp_name }}</th>
                                <td v-for="(status, dat) in statusPipe" :key="dat">{{ getStatusCount(data.status,
            status.name) }}</td>
                                <td>{{ data.total }}</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>


        </div>
    </Layout>
</template>

<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";

import { API } from "@/state/API";
import { POPUP } from "@/state/popup";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
// import { phone } from "phone";
// import CountryFlag from 'vue-country-flag'
import Swal from "sweetalert2";
// import Multiselect from "vue-multiselect";

export default {
    page: {
        title: "CRM Lead Source Table",
        meta: [{ name: "description", content: appConfig.description }],
    },
    components: {
        Layout,
        PageHeader,
        flatPickr,
        // CountryFlag,
        // Multiselect,
    },
    data() {
        return {
            title: "CRM Lead Source Table",
            items: [
                {
                    text: "CRM Lead Source Table",
                },
                {
                    text: "Orders",
                    active: true,
                },
            ],
            orderData: [
                {
                    name: "Dönüş Yok",
                },
            ],
            statusValue: 1,
            statusRefresh: 1,
            filter: null,
            todayLead: null,
            yesterdayLead: null,
            selectedRangeLead: null,
            filterOn: [],
            sortBy: "age",
            isLoading: false,
            sortDesc: false,
            uniqueUtmSources: [],
            uniquePipeline: [],
            statusPipe: [],
            multipleOption: {
                mode: "range",
                maxDate: "today",
                disableMobile: "true",
            },
            formData: {
                user: "estetikInternationalLead",
                password: "777_leadEstetikInternationalYetki",
            },
        };
    },
    computed: {
        rows() {
            return this.orderData.length;
        },

        formattedOrderData() {
            return this.orderData.map(campaign => {
                let formattedStatus = {};
                this.statusPipe.forEach(status => {
                    formattedStatus[status.name] = 0; // Default count is 0 for each status
                });
                campaign.status.forEach(status => {
                    if (formattedStatus[status.status_id] !== undefined) {
                        formattedStatus[status.status_id] = status.count;
                    }
                });
                return {
                    camp_name: campaign.camp_name,
                    status: formattedStatus,
                    total: campaign.total
                };
            });
        }
    },
    mounted() {
        this.totalRows = this.items.length;
        this.ListLp();
    },
    methods: {

        getStatusCount(statuses, statusName) {
            return statuses[statusName] || "-";
        },
        onFiltered(filteredItems) {
            this.totalRows = filteredItems.length;
            this.currentPage = 1;
        },
        convertToFormData() {
            const _formData = new FormData();

            Object.keys(this.formData).map((key) => {
                _formData.append(key, this.formData[key]);
            });

            return _formData;
        },
        async ListLp() {
            this.isLoading = true;
            this.statusRefresh = 0;
            await API.Get(API.CRMLead.LeadSource).then((response) => {
                this.orderData = response.data.orderData;
                console.log(this.orderData);
                var data;
                if (response.data.status === "success") {
                    this.statusPipe = response.data.statusPipe;
                    this.uniquePipeline = response.data.allPipelines;
                    this.selectedRangeLead = response.data.total;

                    data = "Your work has been refresh";
                    POPUP.popupClick(data);
                    this.statusRefresh = 1;
                } else {
                    data = "Your work could not be renewed";
                    POPUP.popupClickNot(data);
                    this.statusRefresh = 1;
                }
            });
        },
        async dateSend() {
            this.isLoading = true;
            this.statusRefresh = 0;
            this.timerPopup();
            await API.Post(API.CRMLead.LeadSource, this.convertToFormData()).then((response) => {
                this.orderData = response.data.orderData;
                console.log(response.data);
                var data;
                if (response.data.status === "success") {
                    this.statusPipe = response.data.statusPipe;
                    this.uniquePipeline = response.data.allPipelines;
                    this.selectedRangeLead = response.data.total;
                    data = "Your work has been refresh";
                    POPUP.popupClick(data);
                    this.statusRefresh = 1;
                } else {
                    data = "Your work could not be renewed";
                    POPUP.popupClickNot(data);
                    this.statusRefresh = 1;
                }
            });
        },

        timerPopup(timerClear = false) {
            let timerInterval;
            Swal.fire({
                position: "top-end",
                title: "Please wait to connect to CMS",
                timer: 3000,
                timerProgressBar: true,
                willClose: () => {
                    clearInterval(timerInterval);
                },
            });
            if (timerClear) {
                clearInterval(timerInterval);
            }
        },
    },
};
</script>
<style scoped>
.table-primary {
    font-weight: bold;
}

.table-secondary {
    background-color: #f8f9fa;
}

.table-responsive {
    max-height: 500px;
    overflow-y: auto;
}

.table-fixed-header thead th {
    position: sticky;
    top: 0;
    background-color: #fff;
    z-index: 1;
}
</style>